import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import DefinitionModal from "components/definitionModal";
import InstallPrompt from "components/util-components/InstallPrompt";
import DataModal from "components/DataModal";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const [modalVisible, setModalVisible] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [tableHeaders, setTableHeaders] = useState([]);

  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.tagName !== "A") {
        const tr = event.target.closest("tr");
        const markdownContentDiv = event.target.closest("div.markdown-content");

        if (tr && markdownContentDiv) {
          const table = tr.closest("table");
          const headers = Array.from(table.querySelectorAll("th")).map(
            (th) => th.innerText
          );
          const rowData = Array.from(tr.children).map((td) => td.innerHTML);
          setRowData(rowData);
          setTableHeaders(headers);
          setModalVisible(true);
        }
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleCloseModal = () => {
    setModalVisible(false);
    setRowData(null);
    setTableHeaders([]);
  };
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <Router>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </Router>
          <DefinitionModal />
          <InstallPrompt />
          <DataModal
            visible={modalVisible}
            rowData={rowData}
            tableHeaders={tableHeaders}
            onClose={handleCloseModal}
          />
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
