import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "auth/FetchInterceptor";
import { message } from "antd";
import { getGlobalLangId } from "utils/commonFunctions";

export const getDefinitionByKey = createAsyncThunk(
  "definition/getDefinitionByKey",
  async (key, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/get_definition_by_key/${key}/${getGlobalLangId()}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);
