import { combineReducers } from "redux";
import { themeSlice } from "./theme/themeSlice";
import { authSlice } from "./auth/authSlice";
import { sectorSlice } from "./sectors/sectorSlice";
import { companySlice } from "./companies/companiesSlice";
import { paymentSlice } from "./payment/paymentSlice";
import { feedbackSlice } from "./feedback/feedbackSlice";
import { definitionSlice } from "./definition/definitionSlice";
import { pricingSlice } from "./pricing/pricingSlice";
import { newsSlice } from "./news/newsSlice";
import { languageSlice } from "./languages/languageSlice";
import { yearSlice } from "./years/yearSlice";
import { particularSlice } from "./particulars/particularSlice";

const reducer = combineReducers({
  theme: themeSlice.reducer,
  auth: authSlice.reducer,
  sectors: sectorSlice.reducer,
  companies: companySlice.reducer,
  payment: paymentSlice.reducer,
  feedback: feedbackSlice.reducer,
  definition: definitionSlice.reducer,
  pricing: pricingSlice.reducer,
  news: newsSlice.reducer,
  languages: languageSlice.reducer,
  years: yearSlice.reducer,
  particulars: particularSlice.reducer,
});

export default reducer;
