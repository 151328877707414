import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";
import { message as antdMessage } from "antd";

export const login = createAsyncThunk(
  "auth/login",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/auth/login", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      antdMessage.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post("/api/auth/register", params, {
        "public-request": "true",
      })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      antdMessage.success(response.data.message);
    } else {
      antdMessage.error(response.data.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (params, thunkAPI) => {
    const response = await secureApi
      .put(`/api/edit_profile/${params.id}`, params.data)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      antdMessage.success(response.data.message);
    } else {
      antdMessage.error(response.data.message);
    }
  }
);

export const getCurrentUserData = createAsyncThunk(
  "user/getCurrentUserData",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/get_user_data/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      antdMessage.error(response.data.message);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/api/auth/change_password`, params)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      antdMessage.success(response.data.message);
      return response.data.data;
    } else {
      antdMessage.error(response.data.message);
    }
  }
);
