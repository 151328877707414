import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getDefinitionByKey } from "./definitionThunk";

const initialState = {
  getDefinitionByKeyRes: { status: 0, data: [] },
};

export const definitionSlice = createSlice({
  name: "definition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDefinitionByKey.pending.toString(), (state) => {
      return {
        ...state,
        getDefinitionByKeyRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getDefinitionByKey.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getDefinitionByKeyRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getDefinitionByKey.rejected.toString(), (state) => {
      return {
        ...state,
        getDefinitionByKeyRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
