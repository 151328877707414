import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "auth/FetchInterceptor";
import { message } from "antd";

export const getCompaniesBySector = createAsyncThunk(
  "companies/getCompanyBySector",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/get_companies_by_sector/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getActiveCompanies = createAsyncThunk(
  "companies/getActiveCompanies",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/api/get_active_companies`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getAnnualReports = createAsyncThunk(
  "companies/getAnnualReports",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/annual/get_annual_reports_by_company_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getAnnualReportSection = createAsyncThunk(
  "companies/getAnnualReportSection",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/annual/get_annual_reports_section/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      if (response.data.code === 500) {
        message.error("There is some error!");
      } else {
        message.error(response.data.message);
      }
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getCompanyInfoByCompanyId = createAsyncThunk(
  "companies/getCompanyInfoByCompanyId",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/get_company_info_by_company_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getQuarterlyReports = createAsyncThunk(
  "companies/getQuarterlyReports",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/quarter/get_quarterly_reports_by_company_id/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getQuarterlyReportSection = createAsyncThunk(
  "companies/getQuarterlyReportSection",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/quarter/get_quarterly_reports_section/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      if (response.data.code === 500) {
        message.error("There is some error!");
      } else {
        message.error(response.data.message);
      }
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);

export const getQuarters = createAsyncThunk(
  "companies/getQuarters",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/api/user/get_all_quarters`)
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);
