import React, { Component } from "react";
import { SearchOutlined, SelectOutlined } from "@ant-design/icons";
import { submitFeedback } from "redux/toolkit/feedback/feedbackThunk";
import { connect } from "react-redux";
import { Modal, Rate, Input, Form, AutoComplete, Button } from "antd";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import APIstatus from "redux/constants/common";
import { withRouter } from "react-router-dom";
import tokenService from "auth/tokenService";
import { getActiveCompanies } from "redux/toolkit/companies/companiesThunk";

const { Search } = Input;
export class BottomNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      feedbackVisible: false,
      feedback: {
        user_id: tokenService.getUserId(),
        stars: 0,
        message: "",
      },
      feedbackErrors: {
        stars: null,
        message: null,
      },
      activeCompanies: [],
    };
  }

  componentDidMount() {
    if (
      this.props.getActiveCompaniesRes.status === APIstatus.SUCCESS &&
      Array.isArray(this.props.getActiveCompaniesRes.data).length > 0
    ) {
      this.setState({ activeCompanies: this.props.getActiveCompaniesRes.data });
    } else {
      this.props.getActiveCompanies();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.getActiveCompaniesRes.status !==
      this.props.getActiveCompaniesRes.status
    ) {
      if (this.props.getActiveCompaniesRes.status === APIstatus.SUCCESS) {
        this.setState({
          activeCompanies: this.props.getActiveCompaniesRes.data,
        });
      }
    }
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showFeedbackModal = () => {
    this.setState({
      feedbackVisible: true,
    });
  };

  handleFeedbackCancel = () => {
    this.setState({
      feedbackVisible: false,
      feedbackErrors: {
        stars: null,
        message: null,
      },
    });
  };

  handleFeedbackChange = (key, value) => {
    this.setState({
      feedback: {
        ...this.state.feedback,
        [key]: value,
      },
      feedbackErrors: {
        ...this.state.feedbackErrors,
        [key]: null,
      },
    });
  };

  validateFeedback = () => {
    const { stars, message } = this.state.feedback;
    const errors = {
      stars: stars === 0 ? "Please provide a stars." : null,
      message:
        message.trim() === "" ? "Please provide feedback message." : null,
    };
    this.setState({ feedbackErrors: errors });
    return !errors.stars && !errors.message;
  };

  handleFeedbackSubmit = () => {
    if (this.validateFeedback()) {
      this.props.submitFeedback(this.state.feedback);
      this.setState({
        feedbackVisible: false,
        feedback: {
          user_id: tokenService.getUserId(),
          stars: 0,
          message: "",
        },
        feedbackErrors: {
          stars: null,
          message: null,
        },
        showSearch: false,
      });
    }
  };

  render() {
    const { feedback, feedbackErrors, activeCompanies, showSearch } =
      this.state;
    const { history } = this.props;
    return (
      <div className="bottom-navbar-container d-md-none d-sm-block">
        {showSearch ? (
          <div key="search" className="mobile-search-bar">
            <AutoComplete
              className="form-control"
              placeholder="Search..."
              options={activeCompanies.map((company) => {
                return {
                  value: company.script_name,
                  id: company.id,
                  script_id: company.script_id,
                  sector_id: company.sectors[0].id,
                  sector_name: company.sectors[0].name,
                };
              })}
              filterOption={(inputValue, option) =>
                option.value.toLowerCase().includes(inputValue.toLowerCase())
              }
              onSelect={(value, option) => {
                const encodedCompanyName = encodeURIComponent(value);
                history.push(
                  `${APP_PREFIX_PATH}/sectors/annual-report/${option.id}/${encodedCompanyName}/${option.script_id}.NS/${option.sector_id}/${option.sector_name}`
                );
              }}
            >
              <Search />
            </AutoComplete>
            <div
              className={
                showSearch
                  ? "fliters-collapse-bg active"
                  : "fliters-collapse-bg"
              }
              onClick={() =>
                this.setState({ showSearch: !this.state.showSearch })
              }
            />
          </div>
        ) : (
          <></>
        )}

        <div className="bottom-navbar d-md-none">
          <a href="#home" className="nav-item" onClick={this.showFeedbackModal}>
            <SelectOutlined className="icon" />
            <span>FeedBack</span>
          </a>
          <a
            href="#search"
            className="nav-item"
            onClick={() =>
              this.setState({ showSearch: !this.state.showSearch })
            }
          >
            <SearchOutlined className="icon" />
            <span>Search</span>
          </a>
        </div>
        <Modal
          className="feedback-modal"
          title="Submit Feedback"
          visible={this.state.feedbackVisible}
          onCancel={this.handleFeedbackCancel}
          onOk={this.handleFeedbackSubmit}
          footer={[
            <Button key="back" onClick={this.handleFeedbackCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              className="btn-dark"
              onClick={this.handleFeedbackSubmit}
            >
              OK
            </Button>,
          ]}
        >
          <Form layout="vertical">
            <Form.Item
              label="Rating"
              validateStatus={feedbackErrors.stars ? "error" : ""}
              help={feedbackErrors.stars}
            >
              <Rate
                value={feedback.stars}
                onChange={(value) => this.handleFeedbackChange("stars", value)}
              />
            </Form.Item>
            <Form.Item
              label="Feedback"
              validateStatus={feedbackErrors.message ? "error" : ""}
              help={feedbackErrors.message}
            >
              <Input.TextArea
                rows={4}
                placeholder="Your feedback"
                value={feedback.message}
                onChange={(e) =>
                  this.handleFeedbackChange("message", e.target.value)
                }
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { feedbackRes } = state.feedback;
  const { getActiveCompaniesRes } = state.companies;
  return { feedbackRes, getActiveCompaniesRes };
};

const mapDispatchToProps = {
  submitFeedback,
  getActiveCompanies,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BottomNavBar)
);
