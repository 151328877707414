import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { submitFeedback } from "./feedbackThunk";

const initialState = {
  feedbackRes: { status: 0, data: [] },
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(submitFeedback.pending.toString(), (state) => {
      return {
        ...state,
        feedbackRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(submitFeedback.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        feedbackRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(submitFeedback.rejected.toString(), (state) => {
      return {
        ...state,
        feedbackRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
