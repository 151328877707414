import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getAllYears } from "./yearsThunk";

const initialState = {
  yearsRes: { status: 0, data: [] },
};

export const yearSlice = createSlice({
  name: "years",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllYears.pending.toString(), (state) => {
      return {
        ...state,
        yearsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getAllYears.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        yearsRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getAllYears.rejected.toString(), (state) => {
      return {
        ...state,
        yearsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
