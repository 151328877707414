import React from "react";
import { Modal } from "antd";

const DataModal = ({ visible, rowData, tableHeaders, onClose }) => {
  return (
    <Modal
      className="feedback-modal"
      zIndex={10}
      title=""
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {rowData &&
        tableHeaders.map((item, index) => {
          return (
            <div className="table-content">
              <strong>{item} :</strong>{" "}
              <span dangerouslySetInnerHTML={{ __html: rowData[index] }}></span>
            </div>
          );
        })}
    </Modal>
  );
};

export default DataModal;
