import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getNewsByCompanyName } from "./newsThunk";

const initialState = {
  getNewsByCompanyNameRes: { status: 0, data: [] },
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNewsByCompanyName.pending.toString(), (state) => {
      return {
        ...state,
        getNewsByCompanyNameRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getNewsByCompanyName.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getNewsByCompanyNameRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getNewsByCompanyName.rejected.toString(), (state) => {
      return {
        ...state,
        getNewsByCompanyNameRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
