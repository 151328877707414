import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import Logo from "./Logo";
import NavPanel from "./NavPanel";
import {
  toggleCollapsedNav,
  onMobileNavToggle,
} from "redux/toolkit/theme/themeSlice";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import { Link, useHistory } from "react-router-dom";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Row, Col, AutoComplete, Input } from "antd";
import { getActiveCompanies } from "redux/toolkit/companies/companiesThunk";
import APIstatus from "redux/constants/common";
// import NavSearch from "../layout-components/NavSearch";
// import {MenuFoldOutlined} from"@ant-design/icons";
// import {MenuUnfoldOutlined} from"@ant-design/icons";

const { Search } = Input;
const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
  } = props;

  const [searchActive, setSearchActive] = useState(false);
  const [activeCompanies, setActiveCompanies] = useState([]);
  const history = useHistory();

  useEffect(() => {
    props.getActiveCompanies();
  }, []);

  useEffect(() => {
    if (props.getActiveCompaniesRes.status === APIstatus.SUCCESS) {
      if (
        props.getActiveCompaniesRes.data &&
        props.getActiveCompaniesRes.data.length
      ) {
        setActiveCompanies(props.getActiveCompaniesRes.data);
      }
    }
  }, [props.getActiveCompaniesRes.status]);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "200px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  }, [isMobile]);

  return (
    <Header
      className={`app-header header-container ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className="header-content d-flex align-items-center justify-content-between flex-wrap">
        <Link to={`${APP_PREFIX_PATH}/app/home`}>
          <Logo logoType={navMode} />
        </Link>
        <div key="search" className="header-searchbar">
          <AutoComplete
            className="form-control"
            placeholder="Search..."
            options={activeCompanies.map((company) => ({
              value: company.script_name,
              id: company.id,
              script_id: company.script_id,
              sector_id: company.sectors[0].id,
              sector_name: company.sectors[0].name,
            }))}
            filterOption={(inputValue, option) =>
              option.value.toLowerCase().includes(inputValue.toLowerCase())
            }
            onSelect={(value, option) => {
              const encodedCompanyName = encodeURIComponent(value);
              history.push(
                `${APP_PREFIX_PATH}/sectors/annual-report/${option.id}/${encodedCompanyName}/${option.script_id}.NS/${option.sector_id}/${option.sector_name}`
              );
            }}
          >
            <Search />
          </AutoComplete>
        </div>
        <div className="nav-right">
          <NavPanel direction={direction} />
        </div>
        {/* <Row gutter={16} align="middle" justify="space-between">
          <Col sm={6}></Col>
          <Col sm={12}></Col>
          <Col sm={6}></Col>
        </Row> */}
        {/* <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile
                ? null
                :  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => {onToggle()}}>
                     {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                   </li>
                  }
            </ul>
          </div>

          <NavSearch active={searchActive} close={onSearchClose} />
        </div> */}
      </div>
    </Header>
  );
};

const mapStateToProps = (store) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = store.theme;
  const { getActiveCompaniesRes } = store.companies;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
    getActiveCompaniesRes,
  };
};

const mapDispatchToProps = {
  toggleCollapsedNav,
  onMobileNavToggle,
  getActiveCompanies,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
