import React from "react";
import { Modal, Spin } from "antd";
import Markdown from "react-markdown";

const DefinitionModal = ({ isVisible, onCancel, loading, definition }) => {
  return (
    <Modal
      title={definition ? definition.term : ""}
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      zIndex={11}
    >
      <Spin spinning={loading}>
        <p>
          {definition ? (
            <Markdown>{definition.value}</Markdown>
          ) : (
            `Loading definition...`
          )}
        </p>
      </Spin>
    </Modal>
  );
};

export default DefinitionModal;
