import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getPricingList } from "./pricingThunk";

const initialState = {
  getPricingRes: { status: "", data: [] },
};

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPricingList.pending.toString(), (state) => {
      return {
        ...state,
        getPricingRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getPricingList.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getPricingRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(getPricingList.rejected.toString(), (state) => {
      return {
        ...state,
        getPricingRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
