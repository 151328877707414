import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "auth/FetchInterceptor";
import { message } from "antd";

export const getPricingList = createAsyncThunk(
  "pricing/getPricingList",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/api/get_market_pricing`, { params: params })
      .catch((e) => {
        return e;
      });
    if (response.data.success) {
      return response.data.data;
    } else {
      message.error(response.data.message);
      return thunkAPI.rejectWithValue(response.data.data);
    }
  }
);
