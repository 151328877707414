import axios from "axios";
import tokenService from "./tokenService";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { message } from "antd";

function getAuth() {
  return `Bearer ${tokenService.getToken()}`;
}

let baseURL = "https://dev.sumgya.com";
// let baseURL = "http://127.0.0.1:5000";

const secureApi = axios.create({
  baseURL: baseURL,
  headers: {
    // Authorization: getAuth(),
  },
});

// Add a request interceptor
secureApi.interceptors.request.use(
  function (config) {
    config.headers.Authorization = getAuth();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
secureApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      tokenService.deleteToken();
      tokenService.deleteUserId();
      tokenService.deleteUserName();
      message.error(
        "You have logged in another device with same credentials. Please login again!"
      );
      setTimeout(() => {
        window.location.replace(`${APP_PREFIX_PATH}/`);
      }, 3000);

      // Here you can call any function you need to handle the 401 response
    }
    return Promise.reject(error);
  }
);

export const api = axios.create({
  baseURL: baseURL,
});

api.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor to `api` as well if needed
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      tokenService.deleteToken();
      tokenService.deleteUserId();
      tokenService.deleteUserName();
      window.location.replace(`${APP_PREFIX_PATH}/`);
      // Here you can call any function you need to handle the 401 response
    }
    return Promise.reject(error);
  }
);

export default secureApi;
