import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getAllParticulars } from "./particularThunk";

const initialState = {
  particularListRes: { status: 0, data: [] },
};

export const particularSlice = createSlice({
  name: "particulars",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllParticulars.pending.toString(), (state) => {
      return {
        ...state,
        particularListRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getAllParticulars.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        particularListRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getAllParticulars.rejected.toString(), (state) => {
      return {
        ...state,
        particularListRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
