import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import Payment from "./payment";
import { ArrowUpOutlined } from "@ant-design/icons";

const Home = lazy(() => import(`./home`));
const CompaniesBySector = lazy(() => import(`./sectors/CompaniesBySector`));
const AnnualReport = lazy(() => import(`./sectors`));
const editUser = lazy(() => import(`./editUser`));
const changePassword = lazy(() => import(`./editUser/changePassword`));

export const AppViews = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route path={`${APP_PREFIX_PATH}/home`} component={Home} />
          <Route path={`${APP_PREFIX_PATH}/payment`} component={Payment} />
          <Route
            path={`${APP_PREFIX_PATH}/sectors`}
            render={({ match: { path } }) => (
              <Switch>
                <Route
                  exact
                  path={`${path}/company-by-sector/:sectorId/:sectorName`}
                  component={CompaniesBySector}
                />
                <Route
                  path={`${path}/annual-report/:companyId/:companyName/:script_id/:sector_id/:sector_name`}
                  component={AnnualReport}
                />
              </Switch>
            )}
          />
          <Route path={`${APP_PREFIX_PATH}/edit-user`} component={editUser} />
          <Route
            path={`${APP_PREFIX_PATH}/change-password`}
            component={changePassword}
          />
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/home`}
          />
        </Switch>
      </Suspense>
      <a
        href="#"
        shape="circle"
        id="scroll-top"
        className={`scroll-top d-flex align-items-center justify-content-center ${
          isScrolled ? "active" : ""
        }`}
      >
        <ArrowUpOutlined />
      </a>
    </>
  );
};

export default React.memo(AppViews);
