class TokenService {
  saveToken = (token) => {
    localStorage.setItem("authToken", token);
  };
  getToken = () => {
    return localStorage.getItem("authToken");
  };
  deleteToken = () => {
    return localStorage.removeItem("authToken");
  };

  saveUserId = (id) => {
    localStorage.setItem("currentUserId", id);
  };
  getUserId = () => {
    return localStorage.getItem("currentUserId");
  };
  deleteUserId = () => {
    return localStorage.removeItem("currentUserId");
  };

  saveUserName = (name) => {
    localStorage.setItem("currentUserName", name);
  };
  getUserName = () => {
    return localStorage.getItem("currentUserName");
  };
  deleteUserName = () => {
    return localStorage.removeItem("currentUserName");
  };
}

export default new TokenService();
