import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import { getSectors } from "./sectorsThunk";

const initialState = {
  getSectorsRes: { status: "", data: [] },
};

export const sectorSlice = createSlice({
  name: "sectors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSectors.pending.toString(), (state) => {
      return {
        ...state,
        getSectorsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSectors.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSectorsRes: { status: APIstatus.SUCCESS, data: action.payload },
      };
    });
    builder.addCase(getSectors.rejected.toString(), (state) => {
      return {
        ...state,
        getSectorsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
