import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "redux/constants/common";
import {
  getCompaniesBySector,
  getAnnualReports,
  getAnnualReportSection,
  getActiveCompanies,
  getCompanyInfoByCompanyId,
  getQuarterlyReports,
  getQuarterlyReportSection,
  getQuarters,
} from "./companiesThunk";

const initialState = {
  getCompaniesBySectorRes: { status: 0, data: [] },
  getAnnualReportRes: { status: 0, data: [] },
  getAnnualReportSectionRes: { status: 0, data: [] },
  getActiveCompaniesRes: { status: 0, data: [] },
  getCompanyInfoByCompanyIdRes: { status: 0, data: [] },
  getQuarterlyReportRes: { status: 0, data: [] },
  getQuarterlyReportsSectionRes: { status: 0, data: [] },
  getQuartersRes: { status: 0, data: [] },
};

export const companySlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompaniesBySector.pending.toString(), (state) => {
      return {
        ...state,
        getCompaniesBySectorRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getCompaniesBySector.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getCompaniesBySectorRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getCompaniesBySector.rejected.toString(), (state) => {
      return {
        ...state,
        getCompaniesBySectorRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getActiveCompanies.pending.toString(), (state) => {
      return {
        ...state,
        getActiveCompaniesRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getActiveCompanies.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getActiveCompaniesRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getActiveCompanies.rejected.toString(), (state) => {
      return {
        ...state,
        getActiveCompaniesRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getAnnualReports.pending.toString(), (state) => {
      return {
        ...state,
        getAnnualReportRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getAnnualReports.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getAnnualReportRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getAnnualReports.rejected.toString(), (state) => {
      return {
        ...state,
        getAnnualReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getAnnualReportSection.pending.toString(), (state) => {
      return {
        ...state,
        getAnnualReportSectionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getAnnualReportSection.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getAnnualReportSectionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getAnnualReportSection.rejected.toString(), (state) => {
      return {
        ...state,
        getAnnualReportSectionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getCompanyInfoByCompanyId.pending.toString(), (state) => {
      return {
        ...state,
        getCompanyInfoByCompanyIdRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getCompanyInfoByCompanyId.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getCompanyInfoByCompanyIdRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getCompanyInfoByCompanyId.rejected.toString(), (state) => {
      return {
        ...state,
        getCompanyInfoByCompanyIdRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getQuarterlyReports.pending.toString(), (state) => {
      return {
        ...state,
        getQuarterlyReportRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getQuarterlyReports.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getQuarterlyReportRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getQuarterlyReports.rejected.toString(), (state) => {
      return {
        ...state,
        getQuarterlyReportRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getQuarterlyReportSection.pending.toString(), (state) => {
      return {
        ...state,
        getQuarterlyReportsSectionRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getQuarterlyReportSection.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getQuarterlyReportsSectionRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getQuarterlyReportSection.rejected.toString(), (state) => {
      return {
        ...state,
        getQuarterlyReportsSectionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getQuarters.pending.toString(), (state) => {
      return {
        ...state,
        getQuartersRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(getQuarters.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getQuartersRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getQuarters.rejected.toString(), (state) => {
      return {
        ...state,
        getQuartersRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
